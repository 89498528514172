.subscription {
  background: #ffffff;
  border-radius: 14px;
  padding: 20px 30px;
  margin: 20px 0;
}

.subscription .userCount {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #616773;
  margin-bottom: 8px;
}

.subscription .businessPlan h6 {
  border-radius: 10px;
  padding: 2px;
  background: #111;
  width: 130px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  color: #ffffff;
  background: #2a2a2e;
  border-radius: 10px;
}

.subscription .userData {
  margin-top: 30px;
}

.subscription .userData .paymentDate {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a2e;
}

.subscription .managePaymentLink {
  border: 1px solid #6414b5;
  border-radius: 60px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6414b5;
  padding: 4px 6px;
}

.subscription .upgradePlan {
  background: #6414b5;
  border-radius: 60px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 8px 15px;
}

.subscription .upgradePlan:hover {
  color: #ffffff !important;
}

.subscription .amount {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #2a2a2e;
  /* margin: 20px 0 0 0; */
}

.paymentDiv {
  margin-right: 30px;
}

.userPayData {
  margin-top: 20px;
}

/* -------------------------------------- */
.invoice {
  background: #b7b6b6;
  border-radius: 14px;
}
.tableCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: #fcfcfc;
  border-radius: 10px 10px 0px 0px;
}

.mainListData {
  padding: 20px 0;
  background: #fcfcfc;
  border-radius: 0px 0px 10px 10px;
  background: #f7f7f7;
}
.invoiceCount {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  color: #2a2a2e;
}

.invoicePDF {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  color: #2a2a2e;
}

.invoiceDate {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #616773;
}

.invoiceDate span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a2e;
}

.ActivePlan {
  color: green;
  font-size: 13px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  margin-left: 5px;
}
.ExpirePlan {
  color: red;
  font-size: 13px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  margin-left: 5px;
}

.subscriptionCheck .updateIcon img {
  width: 100px;
}
.listItems {
  display: flex;
  justify-content: center;
  align-items: center;
}

.keyList {
  margin-right: 5px;
  width: 50%;
  text-align: end;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #616773;
}
.listItems .value {
  width: 50%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #2a2a2e;
  opacity: 0.9;
}

@media (max-width: 1900px){
.invoiceRow {
    flex: 0 0 auto;
    width: 33.333333%;
}
}

@media (max-width: 1100px){
  .invoiceRow {
      flex: 0 0 auto;
      width: 50%;
  }
  }

  @media (max-width: 587px){
    .invoiceRow {
        flex: 0 0 auto;
        width: 100%;
    }
    }

    @media (max-width: 400px){
      .tableCard {
          flex-direction: column;
      }
      }
