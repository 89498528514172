/* Template list start */

/* Template list End */

#card-part {
  background: #eff3f3 !important;
  border-radius: 20px !important;
  border: none !important;
}

/* Edit email start */
/* update template and avaliable template heading code  */
.update-email-heading {
  color: #000000;
  /* font-family: 'Mont-Bold'; */
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
}
/* card header part */
#edit-email-card-heading {
  /* padding: 1rem 2rem !important; */
  /* border:none !important; */
  background: #ececec !important;
  /* border-radius: 20px !important; */
}
#input-signup::placeholder {
  font-family: "Mont-regular";
  font-weight: 600;
  font-size: 14px;
  color: #616773;
  opacity: 0.5;
}
#template_type::placeholder {
  font-family: "Mont-regular";
  font-weight: 600;
  font-size: 14px;
  color: #9097a5;
  /* color: #616773;
opacity:0.5; */
}
#input-template::placeholder {
  font-weight: 600;
  font-size: 14px;
  /* color: #9097A5; */
  /* font-family: "Mont-Regular"; */
  color: #9097a5;
}
/* .save-changes-template{
  color: #fff !important;
  border-color: #F37047 !important;
  background: #F37047 !important;
  border-radius: 55px;
  font-weight: 600;
  font-size: 14px;
  font-family: "Mont-Regular";
  text-align: center;
  padding: 0.5rem 1rem;
  height: 46px;

} */
#card-email-body-part {
  padding: 0rem 2rem !important;
}
#main-card-email {
  background: #ececec !important;
  /* border-radius: 20px !important; */
  border: none !important;
}
.labels {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: gray;
}
/* edit email label field  */
/* .label-field-email{
  color:#5E6278;
  font-family: 'Roboto-Bold';
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 11px;
} */
#input-template {
  min-height: 42px !important;
  border: 1px solid #767b85 !important;
  border-radius: 5px !important;
  box-sizing: border-box;
  padding: 1rem 1rem !important;
  opacity: 50%  !important;
  background-color: #eff3f3 !important;
  font-size: 14px;
}

#input-template:focus {
  box-shadow: none !important;
  min-height: 56px !important;
  border-radius: 5px !important;
  border: 1px solid #6400b0 !important;
  /* padding:1.5rem 1rem !important; */
  background-color: white !important;
}

#template_type {
  cursor: not-allowed;
  box-shadow: none !important;
  min-height: 42px !important;
  border: 1px solid #616773 !important;
  border-radius: 5px !important;
  box-sizing: border-box;
  padding: 1rem 1rem !important;
  opacity: 50%  !important;
  background-color: #eff3f3 !important;
  font-size: 14px;
}

#template_type:focus {
  cursor: not-allowed;
  box-shadow: none !important;
  min-height: 56px !important;
  border: 1px solid #6400b0 !important;
  border-radius: 5px !important;
  /* padding:1.5rem 1rem !important; */
  /* background-color: white !important; */
}
/* .form-control:focus {
  box-shadow: none !important;
  height: 40px !important;
  border-radius: 1px !important;
  background-color: #F5F8FA !important;
  border-color: #ced4da !important;
} */
#input-field-control:focus {
  box-shadow: none !important;
  border-radius: 0.25rem !important;
  background-color: #f9fafb !important;
}
.ql-formats {
  border-right: 1px solid #a5a4a4 !important;
}
/* html editor writing part */
.rdw-editor-main {
  height: 250px !important;
  overflow: hidden !important;
  box-sizing: border-box;
  border: 1px solid #999999;
  /* margin-bottom:7px  !important; */
}
.ql-container {
  height: 250px !important;
  margin-bottom: 8px !important;
}
/*  code for removing elements from editor */

.rdw-fontfamily-wrapper {
  display: none !important;
}
.rdw-colorpicker-wrapper {
  display: none !important;
}
/* .rdw-list-wrapper {
  display: none !important;
} */
.rdw-remove-wrapper {
  display: none !important;
}
.rdw-history-wrapper {
  display: none !important;
}
.rdw-link-wrapper {
  display: none !important;
}
.rdw-emoji-wrapper {
  display: none !important;
}
.rdw-embedded-wrapper {
  display: none !important;
}
.rdw-image-wrapper {
  display: none !important;
}
.rdw-option-wrapper [title="Superscript"] {
  border: none !important;
}
.rdw-option-disabled {
  display: none !important;
}
.rdw-editor-toolbar {
  padding: 0px !important;
}
.edit-template {
  /* height: 48px;
  width: 150px; */
  border-radius: 4px;
  background-color: #24c3ea;
  font-family: "Roboto-Bold";
  color: #ffffff;
}
.edit-template:hover {
  color: #ffffff !important;
}
/* media query */
@media only screen and (min-device-width: 320px) and (max-device-width: 375px) {
  /* .update-email-heading{
    font-size: 17px;
    margin-top:7px;
 } */
  .edit-template {
    height: 38px !important;
    width: 130px !important;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .available-template {
    margin-top: 20px !important;
    margin-bottom: -13px !important;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 406px) {
  .save-changes-template {
    width: 129px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 425px) {
  #edit-email-card-heading {
    padding: 1rem 1.5rem !important;
  }
  .update-email-heading {
    font-size: 17px;
    margin-top: 0.8rem !important;
  }
}
