.sidebar-column {
    width: 265px;
    margin: 0;
    padding: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    height: 100%;
}

.dashboard-column {
    margin: 0; 
    padding: 0; 
    background-color: #ececec;
    width: calc(100% - 265px);
    min-height: 100vh;
    margin-left: 265px;
}

.sidebar-user-box {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media screen and (max-device-width: 991px) and (min-device-width: 50px) {
    .dashboard-column {
        width: 100% !important;
        margin-left: 0 !important;
    }  
}

@media screen and (max-width: 991px) and (min-width: 50px) {
    .dashboard-column {
        width: 100% !important;
        margin-left: 0 !important;
    }  
}