.add-leave-modal-btn, .add-leave-modal-btn:focus {
    border-radius: 6px;
    border-color: rgb(99, 0, 176);
    background: rgb(99, 0, 176);
    font-family: Poppins;
    font-style: normal;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 12px;
    padding: 10px 20px;
    box-shadow: none;
}

.input-checkbox.form-check-input:checked {
    background-color: #6414b5 !important;
    border-color: transparent !important;
}

.input-checkbox.form-check-input:focus {
    border-color: rgb(97, 103, 115);
    outline: 0;
    box-shadow: none;
}

.cancel-leave-btn,
.cancel-leave-btn:focus {
    border: none;
    width: 120px;
    border-radius: 30px;
    background: rgb(97, 103, 115);
    color: rgb(255, 255, 255);
    box-shadow: none;
}

.save-btn,
.save-btn:focus {
    border: none;
    /* width: 120px; */
    border-radius: 30px;
    background: rgb(100, 20, 181);
    color: rgb(255, 255, 255);
    box-shadow: none;
    padding: 0px 32px;
}

.leave-tabs {
    border-bottom: none !important;
}

.leave-tabs .nav-link,
.nav-link.active {
    padding: 8px 41px;
}

.leave-tabs .nav-link.active {
    background-color: #6414b5 !important;
    color: #fff !important;
    border-radius: 5px;
    line-height: 20px;
    height: 40px;
}

.leave-tabs.nav-tabs.nav-link.hover {
    background-color: #6414b5 !important;
    color: #fff !important;
    border-color: 1px solid #6414b5 !important;
    border-radius: 5px;
    height: 40px;
}

.leave-tabs-heading .nav-link {
    color: #6414b5 !important;
    background: transparent;
    border: 1px solid #6414b5 !important;
    border-radius: 5px;
    line-height: 20px;
    height: 40px;
}