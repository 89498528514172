.UserDashboard .date {
  margin-top: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #616773;
}

/* User Timer Details  */
.UserDashboard .userTimeData .userTimeCard {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.UserDashboard .userTimeData .userTimeCard .header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #616773;
}

.UserDashboard .userTimeData .userTimeCard .userTime {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #2a2a2e;
  text-align: center;
}

.UserDashboard .userTimeData .userTimeCard .timeIncDec {
  text-align: center;
  background: rgba(89, 165, 97, 0.28);
  border-radius: 13px;
  padding: 4px 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #59a561;
  margin: 0 30%;
}

.UserDashboard .userTimeData .userTimeCard .timeIncDec span{
  width: 66px;
  height: 26px;
}

.UserDashboard .userTimeData .userTimeCard .idleTime {
  background: rgba(255, 99, 99, 0.28);
  color: #FF0000;
}

/* -------------------------------------------------------------------- */

/* User Summary Details  */
.userSummary .chart {
  width: 60%;
  margin: 30px 0;
}
.userSummary .project {
  margin: 30px 0;
  width: 40%;
}

.userSummary .header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2a2a2e;
}
.userSummary .header .downloadbtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #6414b5;
  opacity: 0.9;
  cursor: pointer;
}

.userSummary .userChart {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 10px;
  margin: 10px 0;
  height: 90%;
}

.userSummary .userProject {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 10px 0;
  height: 90%;
}

.userSummary .userProject table {
  margin: 0 !important;
  width: 100%;
}

.userSummary .userProject table .spentTime {
  float: right;
}

.userSummary .userProject .userProjectDataHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #616773;
  padding: 15px;
}

.userSummary .userProject .userProjectDetails {
  padding: 15px;
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #616773;
}

.userProjectDetails .projectTime {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #018901;
  opacity: 0.9;
  float: right;
}

tr.border-bottom td {
  opacity: 0.5;
  line-height: 4;
  border-bottom: 2px solid #b6b6b6;
}

tbody > tr:last-child > td {
  border: 0;
}

.userProject .viewAllProjectReport {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #6414b5;
  padding-top: 10px;
}

/* progress-circle number css  */
.CircularProgressbar .CircularProgressbar-text {
  font-size: 26px !important;
}
/* -------------------------------------------------------------------- */

/* All Media Quries */
.recentActivity .header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2a2a2e;
}
.recentActivity .header .downloadbtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #6414b5;
  opacity: 0.9;
  cursor: pointer;
}

.recentActivity .screenshotRow{
  margin-right: 1rem !important;
}

.recentActivity .screenshotRow img{
  width: 300px !important;
}


.recentActivity .screenshotRow .screenshotTime {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #616773;
  opacity: 0.9;
}

.recentActivity .header span{
  font-size: 12px;
  color: #616773;
}

/* -------------------------------------------------------------------- */

/* All Media Quries */
@media screen and (max-width: 1100px) {
  .userSummary .chart {
    width: 100%;
  }
  .userSummary .project {
    margin-top: 0px;
    width: 100%;
  }
}
/* -------------------------------------------------------------------- */
