.drop-dwn-new-integration {
  border-radius: 5px;
  border-color: rgb(99, 0, 176);
  background: rgb(99, 0, 176);
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 12px;
  padding: 10px 20px;
}

.integration-li .dropdown-item.active,
.dropdown-item:active {
  background: #928e93 !important;
  color: #fff !important;
  padding: 0.25rem 1rem;
}

.custom-select .css-yk16xz-control {
  min-height: 42px !important;
}

.custom-select .css-1pahdxg-control {
  min-height: 42px !important;
  box-shadow: none !important;
  border: 1px solid #6414b5 !important;
}

.alert-box {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.integration-page .steps-box {
  counter-reset: step;
}

.integration-page .steps-box .step {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.integration-page .steps-box .card {
  margin-bottom: 0.5rem;
  padding: 1rem 1.25rem;
}

.integration-page .steps-box .step .step-count:before {
  background-color: #8f009b;
  border-radius: 50%;
  color: #fff;
  content: counter(step);
  counter-increment: step;
  display: inline-block;
  font-weight: 600;
  height: 2.25rem;
  line-height: 2.25rem;
  margin-right: 1rem;
  text-align: center;
  width: 2.25rem;
}

.integration-page .steps-box .step .step-content .step-heading {
  font-weight: 500;
}

.integrationBtn {
  border-radius: 5px;
  border-color: rgb(99, 0, 176);
  background: rgb(99, 0, 176);
  font-family: Poppins;
  font-style: normal;
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 12px;
  padding: 10px 20px;
}

.integration-page h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  color: #2a2a2e;
}
