.form-box-border {
    background-color: #f0f0f0;
    border: transparent;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.cal-box-border {
    border: 1px solid rgb(99, 0, 176);
    border-radius: 15px;
    color: rgb(99, 0, 176);
    box-shadow: none;
    text-decoration: none;
}

@media screen and (max-device-width: 767px) and (min-device-width: 50px) {
    .p-sm-box {
        padding: 0 !important;
        margin-bottom: 20px !important;
    }
}
