.SubscriptionDetailsCards {
  margin: 20px 0;
}

.SubscriptionDetailsCards .Cards {
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
}

/* .SubscriptionDetailsCards .Cards .subscriptionDetails{
    padding: 20px;
    background: #ffffff;
    border-radius: 10px; 
  } */

.SubscriptionDetailsCards .Cards .subscriptionDetails .monthly {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #616773;
}

.SubscriptionDetailsCards .Cards .subscriptionDetails .monthlyCount {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #2a2a2e;
}

.SubscriptionDetailsCards .Cards .icons .border {
  width: 45px;
  height: 45px;
  background: #f2ebfb;
  border-radius: 50%;
}

/* ---------------------------------------------- */

.summaryDetails .compName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #3f4254;
}

.summaryDetails .otherItem {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a2e;
  opacity: 0.9;
}


/* -----------SuperAdminSupscription css---------------- */
.subscriptionHeader{
    margin: 20px 0;
}

.pricing .EditbtnPricing{
    font-family: 'Poppins';
    margin-left: 4px;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
color: #FFFFFF;
}

.EditPlanForm .lableRepo{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 20px;
color: #616773;
}
.EditPlanForm input::placeholder{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 20px;
color: #B6B6B6;
}

.EditPlanForm input{
    border: 1px solid #ced4da;
}

.EditPlanForm select{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 13px;
height: 40px;
color: #B6B6B6;
}