.padding {
    padding: 3rem !important
}

.user-card-full {
    overflow: hidden;
}

.display-profile-card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px;
}

.profile-details-heading {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 28px;
    color: #3F4254;
}

.edit-profile-icon {
    height: 30px !important;
}

.m-r-0 {
    margin-right: 0px;
}

.m-l-0 {
    margin-left: 0px;
}

.user-card-full .user-profile {
    border-radius: 5px 0 0 5px;
}

.user-profile {
    padding: 20px 0;
}

.card-block {
    padding: 1.25rem;
}

.m-b-25 {
    /* margin-bottom: 25px; */
    margin-bottom: 6px;
}

.img-radius {
    border-radius: 5px;
}

h6 {
    font-size: 14px;
}

.card .card-block p {
    line-height: 25px;
}

.span-text{
    align-items: center;
    font-size: 3.5rem;
}

.save-changes-button{
    border : none !important;
    background: #6414b5 !important;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    height:44px
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px;
    }
}

.card-block {
    padding: 1.25rem;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
    margin-bottom: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.card .card-block p {
    line-height: 25px;
}

.m-b-10 {
    margin-bottom: -1px;
}

.text-muted {
    color: #919aa3 !important;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
    font-weight: 600;
    color: #9097A5;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-t-40 {
    margin-top: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: -1px;
}

.m-t-40 {
    margin-top: 20px;
}

.user-card-full .social-link li {
    display: inline-block;
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.profile-field-text {
    color: #616773;
}

.update-profile-label {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #616773;
}

#profile-update-input-tag {
    opacity: 50% !important;
    border: 1px solid #9097A5;
    border-radius: 30px;
    min-height: 42px;
    padding: 1rem 1rem !important;
    background-color: #F6F8FA !important;
}

.row-manage {
    gap: 20px;
}

.margin-row {
    margin-top: 2.5rem !important;
}

#profile-update-input-tag:focus {
    min-height: 57px !important;
    padding: 1rem 1rem !important;
    border: 1px solid #F37047 !important;
    border-radius: 30px !important;
    background-color: white !important;
}

.update-profile-btn {
    background: #F37047;
    border-radius: 55px;
    padding: 9px 20px;
}

#cancel-profile-edit {
    /* background: #C9CDD5;
    border-radius: 55px; */
    /* color: #9097A5 !important; */
    font-size: 14px;
    font-weight: 600;
}

.padding-profile {
    padding: 0px 1rem !important;
}

/* Change Password Css */
.change-pwd-btn {
    border-radius: 20px;
    background-color: #6414b5 !important;
}



@media only screen and (min-device-width:320px) and (max-device-width:374px) {
    .padding-profile {
        padding: 0px 0.5rem !important;
    }
}

@media only screen and (min-device-width:320px) and (max-device-width:345px) {

    .save-changes-button {
        width: 129px;
        font-size: 12px;

    }

    .row-manage {
        gap: 10px;
    }

    .hide-content {
        padding: 1rem 0px !important;
    }

}