body {
  background-color: #1e1e1e;
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./font-poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url("./font-poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url("./font-poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./font-poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}

.font-poppins-regular {
  font-family: "Poppins-Regular", sans-serif !important;
}

.font-poppins-medium {
  font-family: "Poppins-Medium", sans-serif !important;
}

.font-poppins-semi-bold {
  font-family: "Poppins-SemiBold", sans-serif !important;
}

.font-poppins-bold {
  font-family: "Poppins-Bold", sans-serif !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-34 {
  font-size: 34px !important;
}

@media (max-width: 767px) {
  body {
    background-color: #ffffff !important;
  }
}

.search-input-field {
  opacity: 0.5;
  border: 1px solid #b6b6b6 !important;
  border-radius: 5px !important;
  /* height: 46px; */
  width: 100%;
  background-color: transparent !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3f4254;
  background-image: url("./Images/searchIcon.png");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 95% center;
}

.search-input-field:focus {
  border: 1px solid #6414b5 !important;
  border-radius: 50px;
  box-shadow: none !important;
  /* height: 46px; */
}

.team-select {
  border-radius: 6px !important;
  /* height: 46px; */
}

.team-select:focus {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #6414b5 !important;
}

.form-input-field {
  border: 1px solid #b6b6b6 !important;
  border-radius: 6px !important;
  height: 46px !important;
  color: #2a2a2e !important;
}

.form-input-field:focus {
  /* opacity: 0.5 !important; */
  border: 1px solid #6414b5 !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  height: 46px;
}

.form-text-field {
  border: 1px solid #b6b6b6 !important;
  border-radius: 6px !important;
  color: #2a2a2e !important;
}

.form-text-field:focus {
  /* opacity: 0.5 !important; */
  border: 1px solid #6414b5 !important;
  border-radius: 6px !important;
  box-shadow: none !important;
}

.btn-div-width {
  width: 225px;
}

/* Sidebar code */
#custom-scroll::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f5f5f5;
}

#custom-scroll::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

#custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: linear-gradient(264.71deg, #8f009b 18.58%, #420085 128.77%);
}

#custom-scroll {
  /* For Firefox */
  overflow-y: scroll;
  scrollbar-color: #7e0096 #ffff;
  scrollbar-width: thin !important;
}

.sm-navbar.navbar-toggler {
  padding: 0 !important;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.sm-navbar.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}

.manage-backdrop+div.modal-backdrop.show {
  opacity: 0 !important;
}

/* header dropdown profile card code  */
.header-initials-text {
  background: linear-gradient(264.71deg, #8f009b 18.58%, #420085 128.77%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.profile-dropdown .dropdown-toggle::after {
  display: none !important;
  margin-left: 0 !important;
  content: none !important;
}

.profile-bg,
.profile-bg:hover,
.profile-bg:focus {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  font-size: 0rem !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.slider-box {
  width: 50%;
}

.ss-blur-img {
  /* Add the blur effect */
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.click-to-view {
  position: absolute;padding: 24px 15px;
  /* top: 50%;
  left: 32%;
  transform: translate(-50%, -50%);
  z-index: 2; */
  text-align: center;
}

.pages-main-heading {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  color: #616773;
}

.task-table-height {
  height: 65px;
  vertical-align: middle;
}

.team-select .css-1pahdxg-control {
  border: 1px solid #6414b5 !important;
  border-radius: 6px !important;
  box-shadow: none !important;
}


/* view roles permission ui styles */
.total-users {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #616773;
}

@media screen and (max-device-width: 767px) and (min-device-width: 50px) {
  .slider-box {
    width: auto !important;
  }
}

@media only screen and (min-device-width:320px) and (max-device-width:576px) {
  .f-manage {
    justify-content: center !important;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer components {
  .pagesLinks {
    @apply my-2 text-sm font-bold text-center px-2 tracking-wide;
  }

  .btnProduct {
    @apply bg-blue-500 hover: bg-blue-700 text-white font-bold py-1 px-2 rounded uppercase;
  }

  .centered {
    @apply flex justify-center items-center;
  }
} */