.sign-up-wrapper {
  background: url("../Images/odyseyy-bg.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  width: 100%;
}

.content {
  padding: 2rem 8rem;
}

.mb-7 {
  margin-bottom: 4rem;
}

.gap-22 {
  gap: 22px;
}

.account-head {
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  text-align: right;
  color: #616773;
}

.account-btn {
  border: 1px solid #b6b6b6;
  border-radius: 6px;
  padding: 3px 28px;
}

.bg-left-section {
  background: radial-gradient(
    127.54% 83.68% at 50% 50%,
    #8f009b 0%,
    #420085 100%
  );
  border-radius: 6px 0px 0px 6px;
}

.logo-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-right-form {
  background: #ffffff;
  border-radius: 6px;
}

.pt-pb-3 {
  padding: 2.5rem 8rem;
}

.main-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 51px;
  text-align: center;
  color: #3f4254;
}

.sub-heading {
  /* font-weight: 600; */
  font-size: 14px;
  line-height: 18px;
  color: #616773;
}

.label-field {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #616773;
}

.input-field {
  /* opacity: 0.5; */
  border: 1px solid #b6b6b6;
  border-radius: 6px;
  height: 56px;
  color: #2a2a2e !important;
}

.input-field:focus {
  /* opacity: 0.5 !important; */
  border: 1px solid #6414b5 !important;
  border-radius: 6px;
  box-shadow: none !important;
  height: 56px;
}

.input-field-psd {
  /* opacity: 0.5; */
  border: 1px solid #b6b6b6;
  border-radius: 6px;
  height: 56px;
  color: #2a2a2e !important;
  padding: 5px 37px 5px 12px;
}

.input-field-psd:focus {
  /* opacity: 0.5 !important; */
  border: 1px solid #6414b5 !important;
  border-radius: 6px;
  box-shadow: none !important;
  height: 56px;
  padding: 5px 37px 5px 12px;
}

/* padding: 0 37px 1px 0px; */

.label-field:focus-within {
  color: #6414b5 !important;
}

.eye-icon {
  float: right;
  top: -37px;
  position: relative;
  z-index: 2;
  margin-right: 11px;
  width: 25px;
  height: 25px;
}

.otp-input input {
  border: 1px solid #b6b6b6;
  color: #2a2a2e !important;
  border-radius: 6px;
}

.otp-input input:focus {
  border: 1px solid #6414b5 !important;
  border-radius: 6px;
  box-shadow: none !important;
}

.account-endline {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #616773;
}

.terms-c {
  color: #6414b5;
  font-weight: 500;
}

.submit-btn,
.submit-btn:focus {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  height: 56px;
  width: 277px;
  color: #fff;
  border-radius: 60px !important;
  background: #6414b5;
  box-shadow: none;
}

.grey-links {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #616773;
}

.verify-password-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.h-500 {
  height: 500px;
}

.mt-8 {
  margin-top: 8rem;
}

/* small screen css */
.sm-screen-bg {
  background: #6414b5 !important;
}

.account-sm-head {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #b6b6b6;
}

.account-sm-btn {
  /* width: 76px; */
  border: 1px solid #b6b6b6;
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  padding: 2px 10px;
}

.main-sm-heading {
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #3f4254;
}

.input-sm-field {
  height: 40px;
  border: 1px solid #b6b6b6;
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2a2a2e !important;
}

.input-sm-field:focus {
  border: 1px solid #6414b5 !important;
  border-radius: 6px;
  box-shadow: none !important;
  height: 40px;
}

.eye-sm-icon {
  float: right;
  top: -28px;
  position: relative;
  z-index: 2;
  margin-right: 11px;
}

.account-sm-endline {
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #616773;
}

.submit-sm-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  height: 42px;
  width: 195px;
  color: #fff;
  border-radius: 60px !important;
  background: #6414b5;
  box-shadow: none;
}

.chkbox-input {
  border: 1px solid #b6b6b6;
  border-radius: 3px;
}

.grey-sm-links {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #616773;
}

.otp-input input {
  width: 40px !important;
  height: 40px !important;
  text-align: center;
  margin-right: 20px;
}

.formErr {
  color: #e62c29;
  font-size: 13px;
}

@media screen and (max-device-width: 991px) and (min-device-width: 768px) {
  .content {
    padding: 1rem !important;
  }

  .pt-pb-3 {
    padding: 1rem !important;
  }
}

@media (max-width: 1200px) {
  .content {
    padding: 3rem !important;
  }

  .pt-pb-3 {
    padding: 3rem !important;
  }
}

/* @media (min-width: 1900px) {
    .sign-up-wrapper {
        height: 100vh;
    }
} */

.formErr {
  color: #e62c29;
  font-size: 13px;
}

input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #b6b6b6;
}

::-ms-reveal {
  display: none;
}

.errorPage-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  padding: 5px 30px;
  color: #fff;
  border-radius: 28px !important;
  background: #6414b5;
  box-shadow: none;
}