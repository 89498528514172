.add-view-policy-box {
    justify-content: end;
}

.policy-tabs {
    border-bottom: none !important;
}

.policy-tabs .nav-link,
.nav-link.active {
    padding: 8px 41px;
}

.policy-tabs .nav-link.active {
    background-color: #6414b5 !important;
    color: #fff !important;
    border-radius: 5px;
    line-height: 20px;
}

.policy-tabs.nav-tabs.nav-link.hover {
    background-color: #6414b5 !important;
    color: #fff !important;
    border-color: 1px solid #6414b5 !important;
}

.policy-tabs-heading .nav-link {
    color: #6414b5 !important;
    background: transparent;
    border: 1px solid #6414b5 !important;
    border-radius: 20px;
}
.policyBtn{
    border-radius: 5px;
    border-color : #6300B0;
    background: #6300B0;
    font-family: Poppins;
    font-style: normal;
    color: #FFF;
    font-weight: 500;
    font-size: 12px;
    padding: 10px 20px;
    outline: none;
}
.tick-icon-height {
    height: 33px;
    cursor: pointer;
}

.cross-policy-name {
    border: 1px solid red;
    border-radius: 50%;
    padding: 5px;
}

.delete-policy-btn,
.delete-policy-btn:focus {
    background-color: coral;
    border-radius: 20px;
    padding: 6px 25px;
    box-shadow: none;
}

.save-changes-btn,
.save-changes-btn:focus {
    background-color: #6414b5;
    border-radius: 20px;
    padding: 6px 25px;
    box-shadow: none;
}

@media screen and (max-device-width: 411px) and (min-device-width: 50px) {
    .policy-tabs {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-device-width: 470px) and (min-device-width: 376px) {
    .add-view-policy-box {
        display: flex;
        justify-content: center;
    }
}